import React from 'react';
import { items_activity_data } from '../../data/items_tabs_data';
import Link from 'next/link';

import { get_user_proofs } from 'lib/client/aleo';
import { useWallet } from '@demox-labs/aleo-wallet-adapter-react';
import { useAlert } from 'react-alert';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';


async function loadUserWalletProofs(
	publicKey, requestRecords, alert, setCategory, loading, setLoading, setData
) {
	if (loading)
		return
	setLoading(true);
	try {
		const leos = await get_user_proofs(publicKey, requestRecords);
		console.log("leos", leos);
		setData(leos.sort((a, b) => Number(b.height) - Number(a.height)));
	}
	catch (e) {
		const err_str = e?.error?.message || e?.message || e + "";
		alert.error(err_str);
		setCategory(1);
	}
	setLoading(false);

}


const Activity_tab = ({ setCategory }) => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const { publicKey, requestRecords, requestTransaction } = useWallet();
	const alert = useAlert();
	const router = useRouter();

	useEffect(() => {
		loadUserWalletProofs(
			publicKey, requestRecords, alert, setCategory, loading, setLoading, setData
		);
	}, [publicKey]);


	return (
		<>
			{/* <!-- Activity --> */}



			<div className="tab-pane fade" id="activity" role="tabpanel" aria-labelledby="activity-tab">
				{loading ? (
					<div className="flex justify-center items-center">
						<div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-jacarta-500">
						</div>
					</div>
				) : (
					data.length === 0 ? (<div className="flex justify-center items-center">
						<div className="text-center">
							<div className="text-2xl font-semibold mb-2">No proof recieved yet</div>
							<div className="text-sm text-jacarta-500 dark:text-jacarta-400 normal-case	">
								You will find here proofs sent by user of ownership of NFTs and collections as well as holdership of collection items.
							</div>
						</div>
					</div>) : (
						<div
							role="table"
							className="scrollbar-custom dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 max-h-72 w-full overflow-y-auto rounded-lg rounded-tl-none border bg-white text-sm dark:text-white"
						>
							<div className="dark:bg-jacarta-600 bg-light-base sticky top-0 flex" role="row">
								<div className="w-[25%] py-2 px-4" role="columnheader">
									<span className="text-jacarta-700 dark:text-jacarta-100 w-full overflow-hidden text-ellipsis">
										Type
									</span>
								</div>
								<div className="w-[25%] py-2 px-4" role="columnheader">
									<span className="text-jacarta-700 dark:text-jacarta-100 w-full overflow-hidden text-ellipsis">
										Object
									</span>
								</div>
								<div className="w-[25%] py-2 px-4" role="columnheader">
									<span className="text-jacarta-700 dark:text-jacarta-100 w-full overflow-hidden text-ellipsis">
										Prover
									</span>
								</div>
								<div className="w-[25%] py-2 px-4" role="columnheader">
									<span className="text-jacarta-700 dark:text-jacarta-100 w-full overflow-hidden text-ellipsis">
										Block
									</span>
								</div>
							</div>
							{data.map((item) => {
								console.log("item", item);
								const { id, prover, height, object_type, proof_type, object_hex_id } = item;
								return (
									<div className="flex" role="row" key={object_hex_id + height.toString()}>
										<div
											className="truncate dark:border-jacarta-600 border-jacarta-100 flex w-[25%] items-center border-t py-4 px-4"
											role="cell"
											style={{
												textOverflow: 'ellipsis',
												whiteSpace: "nowrap",
												overflow: "hidden",
												textTransform: "capitalize",
											}}
										>
											{(object_type === "token" && proof_type === "ownership") && (<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 24 24"
												width="24"
												height="24"
												className="fill-jacarta-700 mr-2 h-4 w-4 group-hover:fill-white dark:fill-white"
											>
												<g xmlns="http://www.w3.org/2000/svg" style={{ transform: " scale(0.05)" }} id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
													<g id="icon" fill="#000000" transform="translate(42.666667, 85.333333)">
														<path d="M426.666667,1.42108547e-14 L426.666667,341.333333 L3.55271368e-14,341.333333 L3.55271368e-14,1.42108547e-14 L426.666667,1.42108547e-14 Z M384,42.6666667 L42.6666667,42.6666667 L42.6666667,298.666667 L384,298.666667 L384,42.6666667 Z M341.333333,213.333333 L341.333333,245.333333 L234.666667,245.333333 L234.666667,213.333333 L341.333333,213.333333 Z M341.333333,149.333333 L341.333333,181.333333 L234.666667,181.333333 L234.666667,149.333333 L341.333333,149.333333 Z M192,85.3333333 L192,170.666667 L85.3333333,170.666667 L85.3333333,85.3333333 L192,85.3333333 Z M341.333333,85.3333333 L341.333333,117.333333 L234.666667,117.333333 L234.666667,85.3333333 L341.333333,85.3333333 Z" id="Combined-Shape">
														</path>
													</g>
												</g>									</svg>)}
											{(object_type === "collection" && proof_type === "ownership") && (<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 24 24"
												width="24"
												height="24"
												className="fill-jacarta-700 mr-2 h-4 w-4 group-hover:fill-white dark:fill-white"
												style={{
													transform: "translate(1px, 2px)",
												}}
											>
												<use xlinkHref={`/icons.svg#icon-listing`}></use>
											</svg>)}
											{(object_type === "collection" && proof_type === "holdership") && (<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 24 24"
												width="24"
												height="24"
												className="fill-jacarta-700 mr-2 h-4 w-4 group-hover:fill-white dark:fill-white"
											>
												<g xmlns="http://www.w3.org/2000/svg" style={{ transform: " scale(0.2)" }}>
													<polygon points="91,45 83,45 83,73 73,73 73,119 61,119 61,73 51,73 51,45 43,45 43,81 53,81 53,127 81,127 81,81 91,81  " />
													<path d="M53,15c0,7.7,6.3,14,14,14s14-6.3,14-14S74.7,1,67,1S53,7.3,53,15z M73,15c0,3.3-2.7,6-6,6s-6-2.7-6-6s2.7-6,6-6   S73,11.7,73,15z" />
													<path d="M19,25c0,7.7,6.3,14,14,14s14-6.3,14-14s-6.3-14-14-14S19,17.3,19,25z M39,25c0,3.3-2.7,6-6,6s-6-2.7-6-6s2.7-6,6-6   S39,21.7,39,25z" />
													<polygon points="113,83 103,83 103,119 87,119 87,127 111,127 111,91 121,91 121,51 113,51  " />
													<path d="M87,25c0,7.7,6.3,14,14,14s14-6.3,14-14s-6.3-14-14-14S87,17.3,87,25z M101,19c3.3,0,6,2.7,6,6s-2.7,6-6,6s-6-2.7-6-6   S97.7,19,101,19z" />
													<polygon points="31,83 21,83 21,51 13,51 13,91 23,91 23,127 47,127 47,119 31,119  " />
												</g>
											</svg>)}

											<strong>{object_type + " " + proof_type}</strong>
										</div>
										<div
											className="normal-case truncate dark:border-jacarta-600 border-jacarta-100 flex w-[25%] items-center whitespace-nowrap border-t py-4 px-4"
											role="cell"
										>
											<span className="text-green text-sm font-medium tracking-tight"
												style={{
													textOverflow: 'ellipsis',
													whiteSpace: "nowrap",
													overflow: "hidden",
												}}
											>
												<Link href={`/${object_type}/${object_hex_id}`} passHref>
													<a className="normal-case hover:underline" target="_blank"
														rel="noopener noreferrer">{object_hex_id}</a>
												</Link>
											</span>
										</div>
										<div
											className="normal-case truncate dark:border-jacarta-600 border-jacarta-100 flex w-[25%] items-center border-t py-4 px-4"
											role="cell"
										>
											<Link href={`/user/${prover}`} passHref>
												<a target="_blank"
													rel="noopener noreferrer" className="text-accent normal-case hover:underline" style={{
														textOverflow: 'ellipsis',
														whiteSpace: "nowrap",
														overflow: "hidden"
													}}>{prover}</a>
											</Link>
										</div>
										<div
											className="normal-case truncate dark:border-jacarta-600 border-jacarta-100 flex w-[25%] items-center border-t py-4 px-4"
											role="cell"
											style={{
												textOverflow: 'ellipsis',
												whiteSpace: "nowrap",
												overflow: "hidden",
											}}
										>
											<Link href={`https://explorer.hamp.app/block?h=${height}`} passHref>
												<a className="text-accent hover:underline" target="_blank"
													rel="noopener noreferrer">{height.toString()}</a>
											</Link>
										</div>
									</div>
								);
							})}
						</div>
					)
				)}

			</div>
		</>
	);
};

export default Activity_tab;
